import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Image from "components/UI/image";
import Main from "components/Layout/main";
import StaticPageMeta from "../components/UI/staticPageMeta";

import "styles/about-us-page.scss";

export const contentfulPageQuery = graphql`
query AboutUsQuery {
  allContentfulContentfulPage(
    filter: {name: {eq: "About Us"}}
  ) {
    nodes {
      richText {
        raw
      }
      name
      bannerItems {
        type
        title
        image {
          title
          file {
            url
            contentType
          }
          gatsbyImageData(
            placeholder: NONE,
            formats: [AUTO, WEBP, AVIF],
          )
        }
        imageMobile {
          title
          file {
            url
            contentType
          }
          gatsbyImageData(
            placeholder: NONE,
            formats: [AUTO, WEBP, AVIF],
          )
        }
      }
    }
  }
}
`;

const AboutUs = (props) => {
  const { allContentfulContentfulPage } = useStaticQuery(contentfulPageQuery);
  const aboutUsData = allContentfulContentfulPage.nodes[0];

  return (
    <>
      <StaticPageMeta pageName="About us" />
      <Main className="about-us">
        <div className="about-us__content">
          {renderRichText(aboutUsData.richText)}
          {aboutUsData?.bannerItems?.map((item, index) => (
            <div key={index} className={`about-us__banner ${item.type}`}>
              <div className="about-us__image-container">
                <Image image={item.image} imageMobile={item.imageMobile}
                  className="about-us__image" />
              </div>
              <div className="about-us__title-container">
                <p className="about-us__title">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </Main>
    </>
  );
};

export default AboutUs;
